<template>
    <section>
        <div class="headBox">
            <div class="venueHead">
              <span v-if="isWeixin" @click.stop="closeWebview()" class="back-title">返回</span>
                <svg v-else class="icon" aria-hidden="true" @click.stop="closeWebview()">
                  <use xlink:href="#icon-fanhui"></use>
                </svg>
                <SearchRow @search-call="inputSearch" placeholder="请输入酒店名称" v-model="searchInput" />
                <div class="searchIconBox">
                    <svg class="icon" aria-hidden="true" @click="openSort" style="margin-right: 0.1rem;">
                        <use xlink:href="#icon-paixu"></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" @click="openScreen">
                        <use xlink:href="#icon-shaixuan"></use>
                    </svg>
                </div>
            </div>
        </div>
        <van-popup v-model="show" position="top" :style="{ minHeight: '20%' }">
          <div style="margin-top: .6rem">
            <div  v-if="['screen'].includes(showType)">
              <div class="normalInputRow3">
                <div class="input-container">
                  <p class="input-label">场地类型</p>
                  <div class="fitBox">
                    <a :class="screentValue == item.value && 'selectFit'" @click="changeScreen(item.value)" v-for="(item, index) in screentremGrounp" :key="index">{{ item.text }}</a>
                  </div>
                </div>
              </div>
              <div class="fitBtnBox">
                <div class="fitBtn" @click="resettingScreen">重置</div>
                <div class="fitBtn primary" @click="submitScreen">确认</div>
              </div>
            </div>
            <div v-else>
              <div class="sortList"  v-for="(item, index) in sortList" :key="index">
                <div class="sortItem" @click="changeSort(item.value)">
                  <p>{{item.text}}</p>
                  <svg class="icon" aria-hidden="true" v-if="[item.value].includes(sortValue)">
                    <use xlink:href="#icon-checkbox2"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </van-popup>
    </section>
</template>
<script>
    import SearchRow from "../searchRow/SearchRow";
    import { Popup, DropdownMenu, DropdownItem } from 'vant';
    export default {
        name: 'venueHeader',
        components: {
            SearchRow,
            [Popup.name]: Popup,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
        },
        props: [
            'closeWebview',
            'onSearch'
        ],
        data () {
            return {
                searchInput: "",
                show: false,
                isWeixin: false,
                sortValue: '0',
                screentValue: '0',
                showType: '',
                // 类别排序
                sortList:[
                    {text: "默认优先",value:"0"},
                    {text: "星级优先", value: 'Star'},
                    {text: "好评优先", value: 'Score'},
                ],
                // 星级排序
                screentremGrounp:[
                    { text:'全部', value:'0' },
                    { text:'五星酒店', value:'五星酒店' },
                    { text:'四星酒店', value:'四星酒店' },
                    { text:'三星酒店', value:'三星酒店' },
                    { text:'其他', value:'其他' }
                ],
                // 查询list 参数
                getListParams:{
                    pageIndex: 1,
                    pageSize: 10,
                    orderBy: '',
                    Filters:{
                        city:  this.$SmartStorage.get('eventData').cityDictVal || '',
                        keyword: '',
                        supplierLevel: '',
                    }
                },
            }
        },
      created() {
        this.isWeixin =
            navigator.userAgent.toLowerCase().indexOf("micromessenger") != -1;
      },
      methods: {
            openScreen () {
              if (['screen'].includes(this.showType) && this.show) {
                this.show = false
              } else {
                this.showType = 'screen'
                this.show = true
              }
            },
            openSort () {
              if (['sort'].includes(this.showType) && this.show) {
                this.show = false
              } else {
                this.showType = 'sort'
                this.show = true
              }
            },
            changeSort (val) {
              this.sortValue = val
                if (!['0'].includes(val)) {
                    let params = {
                        Sorts: {}
                    }
                    params.Sorts[val] = 1
                    Object.assign(this.getListParams, params)
                    this.onSearch(this.getListParams)
                } else {
                    this.getListParams && this.getListParams.Sorts && delete this.getListParams.Sorts
                    this.onSearch(this.getListParams)
                }
                this.show = false
            },
            changeScreen (val) {
              this.screentValue = val

            },
          submitScreen () {
            this.getListParams.Filters.supplierLevel = !['0'].includes(this.screentValue) ? this.screentValue : ''
            this.onSearch(this.getListParams)
            this.show = false
          },
          resettingScreen () {
            this.screentValue = '0'
            this.submitScreen()
          },
            inputSearch (val) {
                this.getListParams.Filters.keyword = val
                this.onSearch(this.getListParams)
                this.show = false
            }

        }
    }
</script>
<style lang="scss" scoped>
    .headBox {
        padding: .35rem .35rem .25rem;
        background: #fff;
    }
    .venueHead {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: .1rem .1rem;
        position: fixed;
        width: calc(100% - 0.2rem);
        background: #fff;
        margin-bottom: .2rem;
        z-index: 3000;
        top: 0;
        left: 0;
        .icon{
          width: .2rem;
          height: .2rem;
        }
        .searchIconBox {
            display: flex;
            justify-content: center;
            align-items: center;
          .icon{
            width: .21rem;
            height: .21rem;
          }
        }
    }
    .fitBox {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      a {
        background: #f3f3f3;
        color: #000;
        padding: 0.06rem 0;
        border-radius: 0.04rem;
        margin: 0.05rem 0.08rem 0.05rem 0;
        min-width: 0.8rem;
      }
      .selectFit {
        background-color: var(--themeColor);
        color: #fff;
      }
    }
    .normalInputRow3 {
      padding: 0 .1rem;
      .input-label {
        font-size: .17rem;
        text-align: left;
        font-weight: 500;
      }
    }
    .fitBtnBox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: .1rem;
      .fitBtn {
        padding: .1rem 0;
        width: calc(50% - .08rem);
        border: 1px solid #f3f3f3;
      }
      .primary {
        color: #ffffff;
        background-color: var(--themeColor);
        border-color: var(--themeColor);
      }
    }
    .sortItem {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 .1rem;
      border-bottom: 1px solid #F5F5F5;
      padding: .1rem 0;
      p{
        font-size: .15rem;
      }
      .icon {
        width:.21rem;
        height:.21rem;
        color: #4AC672;
      }
    }
    .back-title {
      color: #333;
      font-size: 0.16rem;
    }
</style>
