<template>
    <section>
        <venue-header :closeWebview="closeWebview" :onSearch="onSearch"></venue-header>
        <div class="venueHeadBtn" v-show="!onlyScan">
          <p class="venueMapTips">若您未找到需要的酒店，可尝试<span class="venueMapBtn" @click="goMap">地图选取酒店</span></p>
        </div>
        <div class="loadmore_container" ref="wrapper" :style="{ height: wrapperHeight + 'px' }">
        <i-scrollBox class="opVenueListScroll" @on-top="scrollTop" :loadType="loadType" @on-bottom="scrollBottom">
            <template v-slot:scrollBox>
                <div v-for="(item, index) in venueList" :key="index">
                    <div :id="'venueList'+index" class="venueList" @click="goVenueDetails(item)">
                        <div class="venueImg">
                            <img :src="item.coverUrl || coverUrl"/>
                            <div class="imgBG"></div>
                        </div>
                        <div class="venueInfo">
                            <p class="venueTitle">{{item.supplierName}}</p>
                            <p class="tagVenueSign" v-if="item.supplierLevel">
                              <svg class="icon" aria-hidden="true">
                                <use xlink:href="#iconshoucang"></use>
                              </svg>
                              {{item.supplierLevel}}
                            </p>
                            <p class="tagVenueSign" v-if="isShowTag(item.isOffLine)"> {{item.tag}}</p>
                            <p class="tagVenueSign" v-if="isShowPlatform(item.isOffLine)"> {{!isShowTenant ? '代' : item.isOffLine ? '非平台' : '平台'}}</p>
                            <p class="tagVenueSign" v-if="['ipsen'].includes(tenant)"> {{item.isOffLine ? '非平台' : ''}} </p>
                            <p class="venueAddress">{{item.address}}</p>
                        </div>
                        <div v-show="!onlyScan" class="selectedVenue" @click.stop="selectVenue(item, index)" :class="item.selected ? 'selected' : ''">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-checkbox2"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <iSkeleton v-if="isFirst" :skList="skList"/>
                <iNoData v-if="!venueList.length&&loadType==''&&!isFirst"/>
            </template>
        </i-scrollBox>
        </div>
      <div class="largeBtn" @click="addVenue" v-show="selectedVenue.length">确定</div>
    </section>
</template>
<script>
    import iScrollBox from "@/components/iScrollBox/iScrollBox";
    import venueHeader from "../../components/venueHeader/venueHeader";
    import generalBridge from "@/plugin/generalBridge";
    export default {
        name: 'venueList',
        components: {
            iScrollBox,
            venueHeader
        },
        data () {
            return {
                isFirst: true,
                onlyScan: false,
                fromOtherSite: '',
                loadType: '',
                searchInput: '',
                appType: '',
                tenant: this.$cookie.get('tenant') || 'roche',
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                venueList: [],
                buttonList: [
                    {actName: '添加酒店', color:'#ff6600', method: 'goMap'},
                    {actName: '确定', color: '#007AFF', method: 'addVenue'},
                ],
                skList: [
                    {
                        width: '65%',
                        row: 5
                    },
                    {
                        width: '20%',
                        position: 'right'
                    },
                    {
                        width: '40%',
                        inline: true
                    },
                    {
                        width: '50%',
                    },
                    {
                        width: '30%',
                        ml: '.1rem'
                    },
                    {
                        width: '15%',
                        inline: true
                    },
                ],
                selectedVenue: [],
                selectedVenueId: [],
                itsItemData: {},
                coverUrl: require('../../assets/img/detailsImg.png'),
                wrapperHeight: 0,
            }
        },
        mounted() {
            this.wrapperHeight = (document.documentElement.clientHeight || window.innerHeight) - this.$refs.wrapper.getBoundingClientRect().top;
        },
        created() {
          if (this.$SmartStorage.get('selectedVenueId')) {
            this.selectedVenueId = this.$SmartStorage.get('selectedVenueId')
          } else {
            this.selectedVenue = this.$SmartStorage.get('venueList') || []
            this.itsItemData = this.$SmartStorage.get('itsItemData') || {}
            this.selectedVenueId = this.selectedVenue.map(ele => ele.supplierId)
          }
          this.onlyScan = this.$SmartStorage.get('onlyScan')
          this.fromOtherSite = this.$route.query.fromOtherSite
          this.getVenueList()
        },
        methods: {
            closeWebview () {
                this.$SmartStorage.remove('selectedVenueId')
                this.$root.$eventHub.$off("venueListGoBack");
                //判断  从其他站点过来（杨磊写的）
                if(['ievent'].includes(this.fromOtherSite)){
                  this.$SmartStorage.set('fromOtherSite', this.fromOtherSite)
                  generalBridge.propy.call(this)
                } else{
                    this.$SmartStorage.remove('maxSelect')
                    this.$router.back()
                }
            },
            onSearch (params) {
                this.getVenueList(true,params)
            },
            onClickBtn (item) {
               this[item.method]()
            },
            goMap () {
                this.$router.push('/map');
            },
            addVenue () {
                if(this.selectedVenue.length > this.maxSelect) {
                    this.$toast('最多可选择'+this.maxSelect+"家供应商")
                    return;
                }
                let result = this.selectedVenue.filter(item => {
                    return item.isOffLine
                }).length > 0
                if (result && ['roche','ipsen'].includes(this.$cookie.get("tenant"))) {
                    this.$dialog({
                        message:
                            "您选择的酒店可能产生上会人员差旅费用，请确认！",
                        showCancelButton: true,
                        confirmButtonText: "确定",
                        cancelButtonText: "取消"
                    }).then(action => {
                        if (action == "confirm") {
                            this.updateVenue()
                        }
                    });
                } else {
                    this.updateVenue()
                }
            },
            updateVenue () {
                // 选择了供应商库酒店则需要将地图选取酒店清除
                this.itsItemData.descr = '';
                this.itsItemData.eventTime = this.itsItemData.eventTime||'';
                this.itsItemData.refer = false;
                this.itsItemData.referAddress = '';
                this.itsItemData.referName = '';
                this.$SmartStorage.set('itsItemData', this.itsItemData)
                this.$SmartStorage.set('venueList', this.selectedVenue)
                this.$router.back()
            },
            scrollTop () {
                this.loadType = 'top';
                this.pageIndex = 1;
                this.getVenueList(true)
            },
            scrollBottom () {
                if (this.pageSize * this.pageIndex >= this.total) {
                    this.loadType = 'none';
                } else {
                    this.loadType = 'bottom';
                    this.pageIndex += 1;
                    this.getVenueList();
                }
            },
            async getVenueList (clear, filterParams = {}) {
              let city = this.$SmartStorage.get('eventData').cityDictVal || ''
              if (['ievent'].includes(this.fromOtherSite)) {
                city = this.$SmartStorage.get('paramCode').city || ''
              }
                this.selectedVenue = []
                let params = {
                    pageIndex: 1,
                    pageSize: 10,
                    orderBy: '',
                    Filters:{
                        city: city,
                        keyword: '',
                        supplierLevel: '',
                    }
                }
                let funName = ['pfizer','viatris'].includes(this.tenant) ? 'pagingSupplier2' : 'pagingSupplier'
                let res = await this.$service[funName]( filterParams.pageIndex ? filterParams : params)
                this.isFirst = false
                if (res && res.success) {
                    if (clear == true) {
                        this.venueList = [];
                    }
                    res.content.rows.forEach(ele => {
                        if (this.selectedVenueId.includes(ele.supplierId)) {
                            ele.selected = true
                            this.selectedVenue.push({
                              supplierId: ele.supplierId,
                              name: ele.supplierName,
                              address: ele.address,
                              selected: ele.selected,
                              isOffLine: ele.isOffLine,
                              coverUrl: ele.coverUrl,
                            })
                        }
                    })
                    this.loadType = null;
                    this.venueList = [...this.venueList, ...res.content.rows]
                    this.total = res.content.total
                    if (this.pageSize * this.pageIndex >= this.total) {
                        this.loadType = 'none';
                    }
                }
            },
            goVenueDetails (item) {
                this.$SmartStorage.set('selectedVenueId' , this.selectedVenueId)
                this.$SmartStorage.set('selectedVenue' , this.selectedVenue)
                this.$router.push({name: 'venueDetails', query: {supplierId: item.supplierId}});
            },
            // 展示协议非协议标签
            isShowTag (isOffLine) {
                return ['roche'].includes(this.tenant) && !isOffLine
            },
            // 展示平台/非平台/代标签
            isShowPlatform (isOffLine) {
                return (['roche','pfizer', 'viatris'].includes(this.tenant)) && isOffLine
            },
            isShowTenant () {
                return ['roche'].includes(this.tenant)
            },
            confirmEvent (method) {
                this[method.method]()
            },
            selectVenue (item, index) {
                let maxSelect = this.$SmartStorage.get('maxSelect')
                if (!item.selected && this.selectedVenueId.length >= maxSelect) {
                    this.$toast(`最多可选择${maxSelect}家供应商`);
                    return
                }
                item.selected = !item.selected
                this.$set(this.venueList , index, item)
                if (!item.selected) {
                    this.selectedVenueId = this.selectedVenueId.filter(ele => {
                        return item.supplierId !== ele
                    })
                    this.selectedVenue = this.selectedVenue.filter(ele => {
                        return item.supplierId !== ele.supplierId
                    })
                    return
                }
                item.selected = true
                this.selectedVenueId.push(item.supplierId)
                this.selectedVenue.push({
                    supplierId: item.supplierId,
                    name: item.supplierName,
                    address: item.address,
                    selected: item.selected,
                    isOffLine: item.isOffLine,
                    coverUrl: item.coverUrl,
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
.venueHeadBtn {
  margin-bottom: .1rem;
  text-align: left;
  padding: 0 0.1rem;
  font-size: .15rem;
  .venueMapTips {
    color: #909399;
  }
  .venueMapBtn {
    margin-left: .1rem;
    color:var(--themeColor);
  }
}
.opVenueListScroll {
  margin: 0.1rem 0.1rem 1rem;
  padding-top: 0.1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #F5F5F5;
    .venueList {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: .05rem;
        .venueImg {
            flex: 1;
            position: relative;
            img {
                width: .8rem;
                height: .8rem;
                border-radius: .1rem;
            }
            .imgBG {
                width: .6rem;
                height: .6rem;
                position: absolute;
                right: -0.07rem;
                top: .1rem;
                background: #F0F0F0;
                z-index: -1;
                border-radius: .1rem;
            }
        }
        .venueInfo {
            flex: 3;
            text-align: left;
            padding: 0 .2rem;
            .venueTitle {
                text-align: left;
                color: #000000;
                font-size: .16rem;
                font-weight: 600;
            }
            .venueAddress {
                color: #90939A;
                font-size: .12rem;
                margin-bottom: .1rem;
                text-align: left;
                .icon {
                    width:.15rem;
                    height:.15rem;
                }
            }

        }
        .selectedVenue {
            color: #dddddd;
            svg {
                width: .2rem;
                height: .2rem;
            }
        }
        .selected {
            color: #4AC672;
        }
    }
    .tagVenueSign {
        color: #FECE30;
        width: auto;
        display: inline-block;
        text-align: left;
        padding: 0 .15rem 0 0;
        font-size: .1rem;
        position: relative;
        .icon {
          width:.12rem;
          height:.12rem;
        }
    }
}
.addVenueRow{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: .2rem 0 .3rem 0;
    box-shadow: 1px -.15rem .15rem #fff;
    .addVenueBtn{
        width: 88%;
        height: .45rem;
        line-height: .45rem;
        border-radius: .3rem;
        margin: 0 auto;
        font-size: .15rem;
    }
}
</style>
