<template>
    <span class="searchRowContent" :style="{ marginLeft:ml}">
        <svg color="#C0C4CD" class="icon searchRowSvg" aria-hidden="true">
            <use xlink:href="#iconsousuo"></use>
        </svg>
        <span class="searchRowInput" style="width: 100%">
            <input type="text" :placeholder="placeholder"
                   v-model="val" :id="'SearchRow'+tag"
                   :disabled="disabled"
                   :style="{
                         width:width,
                         }"
                   @input="changeModel">
        </span>
        <span @click.stop="deleteInput" v-if="val">
            <svg color="#efefef" class="icon searchRowDeleteSvg" aria-hidden="true">
            <use xlink:href="#iconclosewhite"></use>
        </svg>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'SearchRow',
        props: [
            'placeholder',//默认文字
            'width',//搜索框宽度
            'value',//绑定字段
            'disabled',//是否只读
            'ml',//左边距
            'noCancel',//是否有取消按钮 默认有
        ],
        data() {
            return {
                val: '',
                tag: '',
                timer: ''
            }
        },
        created() {
            let math = Math.random().toString().replace('0.', '');
            let date = new Date().getTime();
            this.tag = math + date;
            this.val = this.setInputValue
        },
        methods: {
            changeModel() {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                let _this = this;
                this.timer = setTimeout(function () {
                    _this.$emit('change', _this.val);
                    _this.$emit('search-call', _this.val);
                }, 500);
            },
            deleteInput(e) {
                e.stopPropagation();
                this.val = '';
                this.$emit('change', this.val);
                this.$emit('search-call', this.val);
            }
        },
        watch: {
            'value': function () {
                this.val = this.setInputValue;
            }
        },
        computed: {
            setInputValue() {
                return this.value;
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
    }
</script>

<style lang="scss">
    .searchRowContent {
      height: 0.3rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 0.1rem;
      border: 1px solid #F5F5F5;
      background-color: #F5F5F5;
      margin: 0 0.1rem;
      flex: 1 1 50%
    }

    .searchRowContent:after {
        display: block;
        clear: both;
        content: "";
        visibility: hidden;
        height: 0
    }

    .searchRowContent input {
        border-radius: 0.04rem;
        height: .3rem;
        line-height: .3rem;
        text-align: left;
        outline: none;
        font-size: .15rem;
        background-color: #F5F5F5;
      width: 100%;
    }

    .searchRowSvg {
        width: .15rem !important;
        height: .15rem !important;
        z-index: 2;
    }
    .searchRowInput input:disabled {
        background: #fff;
    }
    .searchRowDeleteSvg {
        width: 0.15rem !important;
        height: 0.15rem !important;
    }
</style>
